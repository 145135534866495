<template>
  <form @submit.prevent="save" class="w-m-content">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">Adicionar pessoa</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <div style="min-height: 400px" class="font-11">
        <erp-s-field label="Nome">
          <!--          <erp-input required autofocus v-model="pessoa.name" />-->
          <nome-pessoa-input ref="nomeInput" :router="router" autofocus v-model="pessoa.name"/>
        </erp-s-field>

        <erp-s-field class="m-t" label="Organização">
          <autocomplete :search="searchOrganizacao" v-model="pessoa.organization" icon="building"/>
        </erp-s-field>

        <erp-s-field class="m-t" label="Classificação">
          <autocomplete-multiple load-on-focus :search="searchPersonTags" v-model="pessoa.tags" placeholder="Nenhum"/>
        </erp-s-field>

        <erp-s-field label="Telefone" class="m-t">
          <e-row v-for="(telefone, index) in pessoa.phoneNumbers" :key="'phone-' + index">
            <e-col class="m-r-xs">
              <erp-input v-model="telefone.phoneNumber"/>
            </e-col>
            <e-col class="m-l-xs">
              <tipo-telefone-select required select-first no-label v-model="telefone.purpose"/>
            </e-col>
            <e-col v-if="pessoa.phoneNumbers.length > 1" class="flex items-center" style="max-width: 20px">
              <a class="m-l-xs" @click="removeTelefone(telefone)">
                <u-icon name="trash" type="fa" color="grey-7"/>
              </a>
            </e-col>
          </e-row>
          <div><a @click="adicionarTelefone" class="font-bold">+ Adicionar telefone</a></div>
        </erp-s-field>

        <erp-s-field label="E-mail" class="m-t">
          <e-row v-for="(email, index) in pessoa.emails" :key="'email-' + index">
            <e-col class="m-r-xs">
              <erp-input v-model="email.email"/>
            </e-col>
            <e-col class="m-l-xs">
              <tipo-email-select required select-first no-label v-model="email.purpose"/>
            </e-col>
            <e-col v-if="pessoa.emails.length > 1" class="flex items-center" style="max-width: 20px">
              <a class="m-l-xs" @click="removeEmail(email)">
                <u-icon name="trash" type="fa" color="grey-7"/>
              </a>
            </e-col>
          </e-row>
          <div><a @click="adicionarEmail" class="font-bold">+ Adicionar email</a></div>
        </erp-s-field>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern">
      <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
             :color="'white'"
             text-color="grey-8"
             class="b-radius-3px btn-white m-r-sm"/>
      <u-btn :loading="loading" type="submit" label="Salvar configurações" no-caps
             :color="'primary'"
             class="b-radius-3px"/>
    </div>
  </form>
</template>

<script>
import ERow from '../../../layout/components/Row'
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'
import ECol from '../../../layout/components/Col'
import Autocomplete from "@/components/layout/components/Autocomplete"
import searchOrganizacao from "@/components/pessoa/helpers/searchOrganizacao"
import searchTags from "@/components/pessoa/helpers/searchTags"
import AutocompleteMultiple from "@/components/layout/components/AutocompleteMultiple"

import {mockTelefone} from "@/components/pessoa/mock/telefone"
import {mockEmail} from "@/components/pessoa/mock/email"
import TipoTelefoneSelect from "@/components/pessoa/components/include/TipoTelefoneSelect"
import TipoEmailSelect from "@/components/pessoa/components/include/TipoEmailSelect"
import PersonMixin from "@/components/pessoa/components/include/PersonMixin"
import NomePessoaInput from "@/components/pessoa/components/include/NomePessoaInput"
import {createPerson} from "@/domain/pessoa/services"

export default {
  name: 'UsuarioWindowModern',
  mixins: [PersonMixin],
  components: {
    NomePessoaInput,
    TipoEmailSelect,
    TipoTelefoneSelect,
    AutocompleteMultiple,
    Autocomplete,
    // WindowLoading,
    ErpInput,
    ErpSField,
    ERow,
    ECol
    // TimerCloseWindow,
    // WindowSuccess,
    // ErpCheckbox
  },
  props: ['options', 'router', 'type'],
  data() {
    return {
      loading: false,
      success: false,
      status: '',
      pessoa: {
        type: this.type || 1,
        name: null,
        organization: null,
        tags: [],
        phoneNumbers: [JSON.parse(JSON.stringify(mockTelefone))],
        emails: [JSON.parse(JSON.stringify(mockEmail))]
      }
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    save() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.pessoa))
      const cb = (data) => {
        createPerson(data)
            .then(response => {
              this.loading = false
              console.log(response)
              this.$uloc.window.emit(this.$root.wid, 'updated', this.lista)
              this.success = true
              this.$uloc.window.close(this.$root.wid)
              this.$uloc.notify({
                color: 'black',
                message: `Nova pessoa "${response.data.name}" criada.`,
                position: 'bottom-left',
                actions: [{
                  label: 'Visualizar',
                  handler: () => {
                    this.gotoPerson(response.data)
                  }
                }]
              })
            })
            .catch(error => {
              this.loading = false
              this.alertApiError(error)
            })
      }
      try {
        if (data.organization && data.organization.id) {
          data.organization = data.organization.id
        }
        if (data.emails && data.emails.length) {
          data.emails = data.emails.filter(e => e.email && String(e.email).length > 5)
        }
        if (data.phoneNumbers && data.phoneNumbers.length) {
          data.phoneNumbers = data.phoneNumbers.filter(e => e.phoneNumber && String(e.phoneNumber).length > 3)
        }
        /* if (data.tags && data.tags.length) {
          data.tags = data.tags.map(tag => tag.id)
        } */
        if (this.$refs.nomeInput.duplicates && this.$refs.nomeInput.duplicates.length) {
          this.$uloc.dialog({
            title: 'Possível pessoa duplicada',
            message: 'Existem nomes parecidos com este, você tem certeza que deseja continuar com o cadastro?',
            ok: 'Sim',
            cancel: 'Não',
            color: 'negative'
          }).then(() => {
            cb(data)
          })
          .catch(() => {
            this.loading = false
          })
        } else {
          cb(data)
        }
      } catch (e) {
        this.alertApiError(null, 'Erro de validação', null, e.message)
        this.loading = false
        return
      }

    },
    searchOrganizacao(terms, done) {
      searchOrganizacao(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova organização`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    },
    searchPersonTags(terms, done) {
      searchTags(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova tag`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    },
    gotoPerson (p) {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'pessoa.show', params: {id: p.id}})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
